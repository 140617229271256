"use client";
import {
  Box,
  BoxProps,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import React, { useRef } from "react";
import { CardHeaderEllipsis } from "modules/common/components/CardHeaderEllipsis";
import { capitalizeFirstLetter, firstUpper, toDateFormat } from "utils/utils";
import { DeckPagedItem, FormatEnum } from "modules/decks/model/deck";
import { LegalityStatus } from "modules/cards/model/card";
import useTheme from "@mui/material/styles/useTheme";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import LinkIcon from "@mui/icons-material/Link";
import { DeckListItemAction } from "modules/decks/components/DeckListItemAction";
import { DeckColorIdentity } from "modules/decks/components/DeckColorIdentity";
import { OverflowTip } from "modules/common/components/OverflowTip";
import { useAuth } from "@clerk/nextjs";
import Link from "modules/common/components/navigation/Link";
import { useRouter } from "next-nprogress-bar";
import { ClerkAvatar } from "modules/common/components/avatar/ClerkAvatar";

export interface DeckListItemProps {
  deckList: DeckPagedItem;
  boxProps?: BoxProps;
  enterDelay: number;
  width?: number;
  height?: number;
  nonDecklist?: boolean;
}

export const DeckListItem = ({
  deckList: {
    name,
    format,
    colors,
    createdAt,
    id,
    legalities,
    updatedAt,
    list,
    userId,
    user,
  },
  enterDelay,
  boxProps,
  width,
  height,
  nonDecklist,
}: DeckListItemProps) => {
  const router = useRouter();
  const theme = useTheme();
  const auth = useAuth();
  const titleRef = useRef(null);
  const allLegal = legalities?.every(
    (l) =>
      l[format?.toLowerCase() as FormatEnum] === LegalityStatus.Legal ||
      l[format?.toLowerCase() as FormatEnum] === LegalityStatus.Restricted
  );
  const isLegalWarning = !allLegal
    ? `${name} has cards that are not ${capitalizeFirstLetter(format)} legal`
    : "";
  const isOwner = auth.userId === userId;

  const handleLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    const element = event.target as HTMLElement;
    if (element.closest(".deck-action-button")) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <Zoom
      in={true}
      timeout={100}
      style={{
        transitionDelay: `${enterDelay}ms`,
      }}
    >
      <Box position={"relative"}>
        <Link
          href={`/decks/${id}`}
          onClick={handleLinkClick}
          style={{
            textDecoration: "none",
          }}
          sx={{
            color: "primary.main",
            textDecoration: "none",
            ...boxProps,
          }}
        >
          <Card
            sx={{
              maxWidth: width ? width : 1,
              minWidth: width ? width : "unset",
              minHeight: height ? height : "unset",
              maxHeight: height ? height : "unset",
              cursor: "pointer",
              bgcolor: "background.default",
              border: allLegal
                ? "unset"
                : `1px solid ${theme.palette.cardLegalities.banned}`,
              display: "flex",
              flexDirection: "column",
            }}
            className={"mouse-over-box-shadow"}
          >
            <CardHeaderEllipsis
              {...{
                sx: {
                  pb: user ? 0 : "undefined",
                },
                titleTypographyProps: {
                  noWrap: true,
                  variant: "h6",
                },
                title: (
                  <OverflowTip value={name} textRef={titleRef}>
                    <span ref={titleRef}>{name}</span>
                  </OverflowTip>
                ),
                subheader: (
                  <Box display={"flex"} gap={1}>
                    <Typography sx={{ color: "text.secondary" }}>
                      {firstUpper(format)}
                    </Typography>
                  </Box>
                ),
              }}
            />
            <CardContent
              sx={{
                pt: nonDecklist ? 0 : undefined,
                pb: nonDecklist ? 0 : undefined,
                marginTop: "auto",
              }}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                flexDirection={"row"}
                alignItems={"flex-end"}
              >
                <>
                  {user && nonDecklist ? (
                    <Box display={"flex"} gap={1} alignItems={"end"}>
                      <Box>
                        <DeckColorIdentity colors={colors} />
                      </Box>
                      <ClerkAvatar
                        sx={{
                          width: 24,
                          height: 24,
                          mr: 1,
                        }}
                        alt={`Avatar image of the user - ${user.username}`}
                        preferredAvatar={user.preferredAvatar}
                      />
                      <Typography
                        variant={"caption"}
                        color={"secondary"}
                        sx={{
                          mt: 0,
                          p: 0,
                        }}
                      >
                        by {user.username}
                      </Typography>
                    </Box>
                  ) : null}

                  {!allLegal ? (
                    <Tooltip
                      title={isLegalWarning}
                      disableHoverListener={allLegal}
                      placement={"top"}
                    >
                      <Box maxWidth={32} display={"flex"} alignItems={"center"}>
                        <PriorityHighIcon color={"error"} />
                      </Box>
                    </Tooltip>
                  ) : null}

                  {!nonDecklist && allLegal ? (
                    <Box display={"flex"} gap={1} alignItems={"flex-end"}>
                      <Box p={0.5}>
                        <DeckColorIdentity colors={colors} />
                      </Box>
                      {list ? (
                        <Tooltip title={`Linked with want list ${list?.name}`}>
                          <IconButton
                            aria-label={
                              "Go to trade list linked with this deck"
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              router.push(`/trade/lists/${list?.id}`);
                            }}
                            sx={{
                              m: 0,
                              p: 0.5,
                              width: "38px",
                            }}
                          >
                            <LinkIcon color={"secondary"} />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </Box>
                  ) : null}
                </>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"flex-end"}
                >
                  <Typography sx={{ fontSize: 13, color: "text.secondary" }}>
                    Updated: {toDateFormat(updatedAt)}
                  </Typography>
                  <Typography sx={{ fontSize: 13, color: "text.secondary" }}>
                    Created: {toDateFormat(createdAt)}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Link>
        {isOwner && !nonDecklist ? (
          <DeckListItemAction
            {...{
              id: id,
              name: name,
            }}
          />
        ) : null}
      </Box>
    </Zoom>
  );
};
