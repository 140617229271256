import React from "react";
import {
  ManaColorCombination,
  manaColorsToNames,
  toManaFontColorIndicator,
} from "utils/utils";
import { Tooltip } from "@mui/material";
import { ManaColorEnum } from "modules/cards/model/card";

interface Props {
  colors: ManaColorEnum[];
}

export const DeckColorIdentity = ({ colors }: Props) => {
  const manaCombination = colors
    .slice()
    .sort((a, b) => a.localeCompare(b))
    .map((c) => c.toLowerCase())
    .join("") as ManaColorCombination;

  const className = `ms ms-ci ms-ci-${
    colors.length || 1
  } ms-ci-${toManaFontColorIndicator(manaCombination)}`;
  return (
    <Tooltip
      title={(manaColorsToNames.get(manaCombination) as string) || "None"}
    >
      <i
        className={className}
        style={{ fontSize: "1.5em", boxShadow: "none" }}
      />
    </Tooltip>
  );
};
