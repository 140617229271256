import {
  FinishEnum,
  Identifiers,
  Legalities,
  MagicCard,
  ManaColorEnum,
} from "modules/cards/model/card";
import {
  CollectionCard,
  SortOrderEnum,
} from "modules/collections/details/model/collection";
import { ShortUser } from "modules/common/model/models";
import { TradeListType } from "modules/tradeLists/model/tradeList";

export enum DeckLocationEnum {
  Mainboard,
  Sideboard,
  Companion,
  Commander,
  Considering,
}

export interface DeckPagedItem {
  createdAt: string;
  userId: string;
  format: FormatEnum;
  updatedAt: string;
  legalities: Legalities[];
  colors: ManaColorEnum[];
  name: string;
  id: string;
  list?: ShortTradeList;
  user?: ShortUser;
}

export interface ShortTradeList {
  id: string;
  name: string;
  type: TradeListType;
}

export interface ShortTradeItem {
  id: string;
  list: ShortTradeList;
}

export const defaultDeckLocations = [
  DeckLocationEnum.Mainboard,
  DeckLocationEnum.Sideboard,
  DeckLocationEnum.Considering,
];

export interface DeckCard {
  data: MagicCard;
  location: DeckLocationEnum;
  quantity: number;
}

export interface DeckCardData {
  id: string;
  types: string[];
  manaValue: number;
  manaCost: string;
  name: string;
  identifiers: Identifiers;
  finishes: FinishEnum[];
}

export interface DeckDetails {
  user: ShortUser;
  userId: string;
  createdAt: string;
  format: FormatEnum;
  updatedAt: string;
  visibility: DeckVisibilityEnum;
  name: string;
  id: string;
  cards: DeckCard[];
  list?: ShortTradeList;
  description: string;
}

export enum FormatEnum {
  Brawl = "brawl",
  Commander = "commander",
  Duel = "duel",
  Future = "future",
  Frontier = "frontier",
  Historic = "historic",
  Legacy = "legacy",
  Modern = "modern",
  Pauper = "pauper",
  Penny = "penny",
  Pioneer = "pioneer",
  Standard = "standard",
  Vintage = "vintage",
}

export interface DeckCreateRequest {
  name: string;
  format: FormatEnum;
  list: string;
  cheapest: boolean;
}

export interface DeckAddCardRequest {
  location: DeckLocationEnum;
  cardId: string;
  quantity: number;
}

export interface DeckRemoveCardRequest {
  location: DeckLocationEnum;
  cardId: string;
}

export interface IdentifiableCard {
  cardId: string;
  quantity: number;
  location: DeckLocationEnum;
}

export interface DeckUpdateCardRequest {
  current: IdentifiableCard;
  next: IdentifiableCard;
}

export enum DeckVisibilityEnum {
  _,
  Private,
  Public,
}

export interface DeckUpdateRequest {
  name: string;
  visibility: DeckVisibilityEnum;
  format: FormatEnum;
  description: string;
}

export enum DeckSortOptions {
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  Name = "name",
}

export interface DecksPagedRequest {
  userId: string;
  query: string;
  page: number;
  perPage: number;
  sort: DeckSortOptions;
  order: SortOrderEnum;
  formatFilter: string;
  visibility?: DeckVisibilityEnum;
}

export interface DecksPagedResponse {
  decks: DeckPagedItem[];
  hasMore: boolean;
  total: boolean;
}

export interface DeckDeleteResponse {
  listId: string;
}

export interface DecksLatestPublicRequest {
  page: number;
  perPage: number;
  format?: FormatEnum;
  userId?: string;
}

export interface DeckCopyRequest {
  deckId: string;
}

export interface OwnedCount {
  cardId: string;
  exact: number;
  total: number;
  alternate: CollectionCard[];
}

export type OwnedCounts = {
  [key: string]: OwnedCount;
};
